<template>
  <div class="shop-settings">
    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('Shop Settings') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Shop Settings') }}</h2>
      </div>
      <div class="form-content">
        <el-form ref="form" :model="form">
          <el-form-item :label="$t('Shop Name (En)')" style="max-width: 400px">
            <el-input v-model="form.nameEn"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Shop Name (Chinese)')" style="max-width: 400px">
            <el-input v-model="form.nameZh"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Shipping Type')" style="max-width: 400px">
            <el-select v-model="form.shippingType">
              <el-option
              v-for="type in shippingTypes"
              :key="type.value"
              :label="type.name"
              :value="type.value">
            </el-option>
           </el-select>
         </el-form-item>

          <el-form-item class="update-button">
            <el-button type="primary" @click="updateSettings">Update</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import { name } from 'dayjs/locale/zh-hk';

export default {
  name: 'ShopSettings',
  mounted(){
    this.loadSettings();
  },
  data(){
    const form = {
      nameEn: 'Octopus Trading',
      nameZh: 'Octopus Trading',
      shippingType: 'free',
    };
    return {
      form,
      shippingTypes: [{
        name: this.$t('Free Shipping'),
        value: 'free'
      },
      {
        name: this.$t('Fixed Shipping Fee'),
        value: 'fixed'
      }
     ]
    }
  },
  methods: {
    async loadSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let nameEn = await Common.loadConfig(this.apiUrl, 'shop_name_en', loginInfo);
        let nameZh = await Common.loadConfig(this.apiUrl, 'shop_name_zh', loginInfo);
        let shippingType = await Common.loadConfig(this.apiUrl, 'shipping_type', loginInfo);
        this.form.nameEn = nameEn;
        this.form.nameZh = nameZh;
        this.form.shippingType = shippingType;
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        await Common.updateConfig(this.apiUrl, 'shop_name_en', this.form.nameEn, loginInfo);
        await Common.updateConfig(this.apiUrl, 'shop_name_zh', this.form.nameZh, loginInfo);
        await Common.updateConfig(this.apiUrl, 'shipping_type', this.form.shippingType, loginInfo);
        this.$message({
          message: '已成功更新商店資料',
          type: 'success'
        });
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.shop-settings{
  .form-content{
    padding-left: 20px;
    .shop-image-wrapper{
      display: flex;
      .shop-logo{
        margin-right: 50px;
      }
    }
    .el-form-item__label{
      float: none;
    }
    .update-button{
      .el-form-item__content{
        text-align: right;
      }
    }
  }
}
</style>
